import PropertyCreatePage from '@/views/PropertyCreate/index.vue'
import CourseBtn from '@/components/CourseBtn.vue'
// import NewGuideComponent from '@/components/NewGuide/index.vue'
export default {
	name: 'PropertyListPage',
	components: {
		PropertyCreatePage,
		CourseBtn,
		// NewGuideComponent
	},
	data() {
		return {
			homeList: [],
			// 搜索条件
			propertyQuery: '',
			// 排序方式
			propertyOrderby: '',
			// 翻页配置
			pagesObj: {
				page: 1,
				pagesize: 12,
				total: 0
			},
			drawer: false,
			peNumLimit: false,
			// 加载向导
			guideVisible: false,
			// 步骤描述配置
			guideDescConfig: null
		}
	},
	computed: {
		guideSteps() {
			return [
				{
					element: '#create-pe-btn', popover: {
						description: this.guideDescConfig.property.create_property_tips_createBtn,
						side: 'left',
						align: 'start',
						showButtons: ['next'],
						nextBtnText: this.$t('guide.goCreate'),
						onNextClick: () => {
							this.createProperty()
						}
					}
				},
				{
					element: '#property_name', popover: {
						description: this.getStepDesc('name_' + this.$store.getters.guideScene),
						side: 'bottom',
						align: 'end',
						onPrevClick: () => {
							this.closeDrawer()
							window.guideDriver.movePrevious()
						}
					}
				},
				{
					element: '#property_abbr_name', popover: {
						description: this.getStepDesc('abbr_name_' + this.$store.getters.guideScene),
						side: 'bottom',
						align: 'end',
					}
				},
				{
					element: '#property_industry', popover: {
						description: this.guideDescConfig.property.create_property_tips_industry,
						side: 'bottom',
						align: 'end',
					},
				},
				{
					element: '#property_upload', popover: {
						description: this.guideDescConfig.property.create_property_tips_upload,
						side: 'bottom',
						align: 'end',
					},
				},
				{
					element: '#property_save', popover: {
						description: this.guideDescConfig.property.create_property_tips_confirm,
						side: 'top',
						align: 'center',
						nextBtnText: this.$t('guide.ok'),
						onNextClick: () => {
							// 如果有物业直接关闭，没有则不能关闭，需等完成创建后才能关闭，关闭逻辑卸载创建组件那边
							// if (this.homeList.length) {
							// 	window.guideDriver.destroy()
							// 	this.closeDrawer()
							// } else {
							const saveBtn = document.querySelector('#property_save')
							saveBtn && saveBtn.click()
							window.guideDriver.destroy()
							// }
						}
					},
				},
				{
					element: '.property-list-item', popover: {
						description: this.guideDescConfig.property.new_pe_here,
						side: 'top',
						align: 'end',
						prevBtnText: this.$t('guide.continueCreate'),
						nextBtnText: this.$t('guide.nextStage'),
						onPrevClick: () => {
							window.guideDriver.drive(0)
						},
						onNextClick: () => {
							window.guideDriver.destroy()
							this.$pubsub.publish('overviewStepEvent', { key: 'create_account', show: true })
						}
					},
				},
			]
		}
	},
	mounted() {
		this.homelist()
		// this.getGuideDesc()
		// this.$setLanguageChangeCallback(this.getGuideDesc)
	},
	methods: {
		// 获取向导描述
		getGuideDesc() {
			if (!this.$store.getters.selfregister) return
			this.$http.post('/v2/guide/list', {
				hrid: this.$store.getters.loginid,
				langcode: this.$store.getters.lang,
				pageName: '',
				type: '',
			}).then(res => {
				if (res.data.success) {
					let config = {}
					res.data.content.forEach(c => {
						config[c.pageName] = {}
						c.steps.forEach(s => {
							config[c.pageName][s.type] = s.description
						})
					})
					if (!config.property) return
					this.guideDescConfig = config
					this.guideVisible = true
					this.$nextTick(() => {
						if (this.$store.getters.isShowGuide) {
							window.guideDriver.drive()
						} else {
							this.$pubsub.publish('overviewStepEvent', { key: 'create_property' })
							this.$pubsub.publish('resetStepsEvent')
						}
					})
				} else {
					this.$alert(res.data.message, this.$t('common.alertPrompt'), {
						type: 'error',
						center: true
					})
				}
			}).catch(() => { })
		},
		// 获取步骤描述
		getStepDesc(key) {
			let str = 'create_property_tips_' + key
			// 没有匹配到就返回空
			return this.guideDescConfig.property[str] || ''
		},
		// 获取物业
		homelist(page) {
			if (page) {
				this.pagesObj.page = page
			} else {
				this.pagesObj.page = 1
			}
			return new Promise((resolve, reject) => {
				this.$http
					.post('/home/homelist', {
						hrid: this.$store.getters.loginid,
						penum: this.$store.getters.penum,
						query: this.propertyQuery,
						page: this.pagesObj.page,
						pagesize: this.pagesObj.pagesize,
						orderby: this.propertyOrderby,
					}, {
						loadTarget: this.$refs.propertyListBox
					})
					.then((response) => {
						if (response.data.success) {
							this.homeList = response.data.content;
							this.peNumLimit = response.data.isPeNumOverLimit;

							this.pagesObj = {
								page: response.data.page,
								pagesize: response.data.pagesize,
								total: response.data.total
							}
							resolve()
						} else {
							this.$alert(response.data.message, this.$t('common.alertPrompt'), {
								type: 'error',
								center: true
							})
							reject()
							// showDialog(_this.pageText.PROMPT, response.data.message, 'error', '', [_this.pageText.OK]);
						}
						// loading.close()
					})
			})
		},
		// 切换物业
		switchCurrentInfo(item) {
			if (this.$store.getters.penum != item.penum) {
				this.$refs.webLayout.headerChangePeDetail(item.penum, '');
			}
		},
		// 点击搜索
		searchEvent() {
			this.homelist()
		},
		// 创建物业
		createProperty() {
			if (this.peNumLimit) {
				this.$alert(this.$t('propertyList.numberLimit'), this.$t('common.alertPrompt'), {
					type: 'error',
					center: true
				})
				this.$store.getters.isShowGuide && window.guideDriver.destroy()
				return;
			} else {
				// _this.handleNextStep()
				this.drawer = true
				if (this.$store.getters.isShowGuide) {
					this.$nextTick(() => {
						setTimeout(() => {
							window.guideDriver.moveNext()
						}, 300)
					})
				}
			}

		},
		// 关闭弹窗
		closeDrawer() {
			this.drawer = false
		},
		// 创建物业成功
		createPropertySuccess(penum) {
			// 如果是跟着向导创建的，将按时间降序
			if (this.$store.getters.isShowGuide) {
				window.guideDriver.destroy()
				this.propertyOrderby = 'crttime_desc'
				this.homelist().then(() => {
					this.$nextTick(() => {
						window.guideDriver.drive(this.guideSteps.length - 1)
					})
				}).catch(() => { })
			} else {
				this.homelist()
			}
			this.$refs.webLayout.headerChangePeDetail(penum);
		}
	}
}